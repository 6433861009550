<template>
  <b-tabs
    vertical
    content-class="col-12 col-md-9 mt-1 mt-md-0"
    pills
    nav-wrapper-class="col-md-3 col-12"
    nav-class="nav-left"
  >
    <!-- RUoli -->
    <b-tab>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="InfoIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">DVR</span>
      </template>

      <b-card>DVR content</b-card>

    </b-tab>

    <!-- change password tab -->
    <b-tab>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="LockIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">GDPR</span>
      </template>

      <b-card>GDPR content</b-card>

    </b-tab>
    <!--/ change password tab -->

    <!-- Gruppi -->
    <b-tab>
      <!-- title -->
      <template #title>
        <feather-icon
          icon="LinkIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Documentale</span>
      </template>
      <b-card>Documentale content</b-card>
    </b-tab>

    <!-- Login History -->
    <b-tab>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="BellIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Commercialista</span>
      </template>

      <b-card>Commercialista content</b-card>
    </b-tab>
    <!-- Action History -->
    <b-tab>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="Link2Icon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Procedure ISO/9001</span>
      </template>

      <b-card>Procedure ISO/9001 content</b-card>
    </b-tab>
  </b-tabs>
</template>

<script>
/* eslint-disable global-require */
import { BTabs, BTab, BCard } from 'bootstrap-vue'

export default {
  components: {
    BTabs,
    BTab,
    BCard,
  },
  data() {
    return {

    }
  },
  beforeCreate() {},
  beforeMount() {},
  methods: {

  },
}
</script>
